import enrolParentReducer from "@/store/features/enrolment/slices/enrolParentSlice";
import enrolStudentReducer from "@/store/features/enrolment/slices/enrolStudentSlice";
import tokenInfoReducer from "@/store/features/enrolment/slices/tokenInfoSlice";
import useEnrolDispatch from "@/store/features/enrolment/useEnrolDispatch";
import useEnrolSelector from "@/store/features/enrolment/useEnrolSelector";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";

const enrolStudentPersistConfig = {
    key: "enrolStudent",
    storage: sessionStorage
};

const tokenInfoPersistConfig = {
    key: "tokenInfo",
    storage: sessionStorage
};

const enrolParentConfig = {
    key: "enrolParent",
    storage: sessionStorage,
    blacklist: ["createParentEnrolRequestStatus"]
};

const persistedEnrolStudentReducer = persistReducer(
    enrolStudentPersistConfig,
    enrolStudentReducer
);

const persistedTokenInfoReducer = persistReducer(
    tokenInfoPersistConfig,
    tokenInfoReducer
);

const persistedEnrolParentReducer = persistReducer(
    enrolParentConfig,
    enrolParentReducer
);

const enrolReducer = combineReducers({
    enrolStudent: persistedEnrolStudentReducer,
    tokenInfo: persistedTokenInfoReducer,
    enrolParent: persistedEnrolParentReducer
});

export default enrolReducer;

export { useEnrolDispatch, useEnrolSelector };
