import { REQUEST_STATUS } from "@/ts/enums/RequestStatus";
import { createSlice } from "@reduxjs/toolkit";

import { resolveNotifications } from "../notificationThunks";

interface NotificationsState {
    resolveStatus: REQUEST_STATUS;
    error: string | null;
}

const initialState: NotificationsState = {
    resolveStatus: REQUEST_STATUS.IDLE,
    error: null
};

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(resolveNotifications.pending, state => {
                state.resolveStatus = REQUEST_STATUS.PENDING;
                state.error = null;
            })
            .addCase(resolveNotifications.fulfilled, state => {
                state.resolveStatus = REQUEST_STATUS.SUCCESS;
            })
            .addCase(resolveNotifications.rejected, (state, action) => {
                state.resolveStatus = REQUEST_STATUS.ERROR;
                state.error =
                    action.error.message || "Failed to resolve notifications";
            });
    }
});

export default notificationsSlice.reducer;
