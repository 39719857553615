import { resolveNotificationsApi } from "@/api/parentsApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface ResolveNotificationsParams {
    notificationIds: number[];
}

export interface NotificationResponse {
    success: boolean;
    message: string;
    data: {
        resolvedIds: number[];
    };
}

export const resolveNotifications = createAsyncThunk<
    NotificationResponse,
    ResolveNotificationsParams,
    { rejectValue: string }
>("resolveNotifications", async ({ notificationIds }, { rejectWithValue }) => {
    try {
        const response = await resolveNotificationsApi({ notificationIds });

        return response.data;
    } catch (error) {
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
        return rejectWithValue("Failed to resolve notifications");
    }
});