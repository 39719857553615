import { REQUEST_STATUS } from "@/ts/enums/RequestStatus";
import { LearningAreaApiInterface } from "@/ts/interfaces/Electives/api/ElectivesApiInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { fetchLearningAreasThunk } from "../electivesThunk";

interface LearningAreasState {
    learningAreas: LearningAreaApiInterface[] | null;
    userSelectedLearningArea: number;

    getLearningAreasRequestStatus: REQUEST_STATUS;
    getLearningAreasRequestError: string | null;
}

const initialState: LearningAreasState = {
    learningAreas: null,
    userSelectedLearningArea: 0,

    getLearningAreasRequestStatus: REQUEST_STATUS.IDLE,
    getLearningAreasRequestError: null
};

const learningAreasSlice = createSlice({
    name: "learningAreas",
    initialState,
    reducers: {
        setLearningAreas(
            state,
            action: PayloadAction<LearningAreaApiInterface[] | null>
        ) {
            state.learningAreas = action.payload;
        },
        setUserSelectedLearningArea(state, action: PayloadAction<number>) {
            state.userSelectedLearningArea = action.payload;
        },
        resetLearningAreasState: state => {
            state.learningAreas = null;
            state.userSelectedLearningArea = 0;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(fetchLearningAreasThunk.pending, state => {
                state.getLearningAreasRequestStatus = REQUEST_STATUS.PENDING;
                state.getLearningAreasRequestError = null;
            })
            .addCase(fetchLearningAreasThunk.fulfilled, (state, action) => {
                state.getLearningAreasRequestStatus = REQUEST_STATUS.SUCCESS;
                state.learningAreas = action.payload.data;
            })
            .addCase(fetchLearningAreasThunk.rejected, (state, action) => {
                state.getLearningAreasRequestStatus = REQUEST_STATUS.ERROR;
                state.getLearningAreasRequestError = action.payload as string;
            });
    }
});

export const { setLearningAreas, setUserSelectedLearningArea, resetLearningAreasState } =
    learningAreasSlice.actions;

export default learningAreasSlice.reducer;
