import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";

import notificationsReducer from "./slices/notificationSlice";
import programReducer from "./slices/programSlice";
import userReducer from "./slices/userSlice";
import useParentDispatch from "./useParentDispatch";
import useParentSelector from "./useParentSelector";

// Persist configs
const userPersistConfig = {
    key: "parentUser",
    storage: sessionStorage
};

const programPersistConfig = {
    key: "parentProgram",
    storage: sessionStorage,
    blacklist: ["cancelProgramStatus", "resolveStatus"]
};

// Create persisted reducers
const persistedUserReducer = persistReducer(userPersistConfig, userReducer);
const persistedProgramReducer = persistReducer(
    programPersistConfig,
    programReducer
);

// Combine all reducers
const parentReducer = combineReducers({
    program: persistedProgramReducer,
    user: persistedUserReducer,
    notifications: notificationsReducer
});

export default parentReducer;

export { useParentDispatch, useParentSelector };
