import { ElectiveDetailInterface } from "@/ts/interfaces/Electives/ElectiveInterface";
import {
    DeleteElectiveResponse,
    DraftElectiveItemInterface,
    ElectiveListApiRequest,
    ElectiveResponse,
    ElectivesRulesParams,
    SaveStudentElectivesResponse,
    SelectedElectiveValidateRequest,
    StudentElectivesResponse,
    ValidateStudentElectivesResponse,
    ValidateStudentElectivesWarningsResponse,
    ValidateStudentStateRequirementPayload
} from "@/ts/interfaces/Electives/api/ElectivesApiInterface";
import { ApiResponseInterface } from "@/ts/interfaces/Misc/api/ApiResponseInterface";
import axios from "axios";

declare function route(
    name: string,
    params?: string | number | { [key: string]: string | number }
): string;

export const fetchLearningAreasApi = () => {
    return axios.get(route("api.elective.learningAreas")); // nosonar
};

export const fetchElectivesListApi = (data: ElectiveListApiRequest) => {
    const { studentId, grade: pfGrade, learningAreaId } = data;
    const queryParams = {
        learning_area_id: learningAreaId ?? undefined
    };

    return axios.get(
        route("api.elective.list", { student: studentId, pfGrade }),
        {
            params: queryParams
        }
    ); // nosonar
};

export const getEnrolledStudentElectivesApi = (
    student: number,
    grade: number
): Promise<ApiResponseInterface<StudentElectivesResponse>> => {
    return axios.get(
        route("api.get.enrolled.student.electives", { student, pfGrade: grade })
    );
};

export const getElectivesRulesApi = ({
    studentId,
    country,
    state
}: ElectivesRulesParams) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = { student: studentId, country, stateId: state };
    return axios.get(route("api.elective.rule", params));
};

export const validateStudentStateRequirementApi = (
    studentId: number,
    countryId: string,
    stateId: string,
    payload: ValidateStudentStateRequirementPayload[]
): Promise<ApiResponseInterface<ValidateStudentElectivesResponse>> => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params: any = { student: studentId, country: countryId };
    if (stateId) {
        params["stateId"] = stateId;
    }

    return axios.post(route("api.elective.all.validate", params), payload);
};

export const validateStudentElectivesApi = (
    params: {
        student: number;
        stateId: string;
        country: string;
        pfGrade: number;
    },
    payload: SelectedElectiveValidateRequest
): Promise<ApiResponseInterface<ValidateStudentElectivesWarningsResponse>> => {
    return axios.post(route("api.elective.validate", params), payload);
};

export const saveDraftStudentElectivesApi = (
    params: { student: number },
    payload?: { elective_ids: string[] }
): Promise<ApiResponseInterface<SaveStudentElectivesResponse>> => {
    return axios.post(
        route("api.post.draft.student.electives", params),
        payload
    );
};

export const enrolStudentForElectivesApi = (
    params: { student: number; pfGrade: number },
    payload?: { elective_ids: number[] }
): Promise<ApiResponseInterface<SaveStudentElectivesResponse>> => {
    return axios.post(
        route("api.post.enroll.student.electives", params),
        payload
    );
};

export const getStudentDraftElectivesApi = (params: {
    user: number;
    pfGrade: number;
}): Promise<
    ApiResponseInterface<{
        message: string;
        data: DraftElectiveItemInterface[];
    }>
> => {
    return axios.get(route("api.get.student.draft.electives", params));
};

export const deleteStudentElectiveApi = (params: {
    student: number;
    subjectSelection: number;
}): Promise<ApiResponseInterface<DeleteElectiveResponse>> => {
    return axios.put(route("api.student.elective.delete", params));
};

export const fetchElectiveDetailsApi = (
    electiveId: number
): Promise<ApiResponseInterface<ElectiveResponse<ElectiveDetailInterface>>> => {
    return axios.get(route("api.get.elective.details", { electiveId }));
};

export const getNotEnrolledStudentElectivesApi = (
    token: string,
    gradeId?: number
) => {
    return axios.get(
        route("api.get.enrolling.student.electives", {
            tokenId: token,
            gradeId: (gradeId as number) ?? undefined
        })
    );
};
