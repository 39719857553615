import { REQUEST_STATUS } from "@/ts/enums/RequestStatus";
import { TokenInfoInterface } from "@/ts/interfaces/Enrol/TokenInfoInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { updateTokenInfoThunk } from "../enrolmentThunks";

interface UpdateTokenInfoState {
    tokenInfo: TokenInfoInterface;

    updateTokenInfoRequestStatus: REQUEST_STATUS;
    updateTokenInfoRequestError: string | null;
}

const initialState: UpdateTokenInfoState = {
    tokenInfo: {
        programs: null,
        student_id: 0,
        student_first_name: "",
        student_last_name: ""
    },

    updateTokenInfoRequestStatus: REQUEST_STATUS.IDLE,
    updateTokenInfoRequestError: null
};

const tokenInfoSlice = createSlice({
    name: "tokenInfo",
    initialState,
    reducers: {
        setUpdatedTokenInfo(state, action: PayloadAction<TokenInfoInterface>) {
            state.tokenInfo = action.payload;
        },
        resetTokenInfoState() {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            // Update token info
            .addCase(updateTokenInfoThunk.pending, state => {
                state.updateTokenInfoRequestStatus = REQUEST_STATUS.PENDING;
                state.updateTokenInfoRequestError = null;
            })
            .addCase(updateTokenInfoThunk.fulfilled, state => {
                state.updateTokenInfoRequestStatus = REQUEST_STATUS.SUCCESS;
            })
            .addCase(updateTokenInfoThunk.rejected, (state, action) => {
                state.updateTokenInfoRequestStatus = REQUEST_STATUS.ERROR;
                state.updateTokenInfoRequestError = action.payload as string;
            });
    }
});

export const { setUpdatedTokenInfo, resetTokenInfoState } =
    tokenInfoSlice.actions;

export default tokenInfoSlice.reducer;
