import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UserLoginInfoInterface } from "@ts/interfaces/Parent/UserInterface";

interface UserState extends UserLoginInfoInterface {
    loading: boolean;
    error: string | null;
}

const initialState: UserState = {
    userName: "",
    rememberMe: false,
    loading: false,
    error: null
};

const userSlice = createSlice({
    name: "parentUser",
    initialState,
    reducers: {
        setUserLogin: (
            state,
            action: PayloadAction<UserLoginInfoInterface>
        ) => {
            state.userName = action.payload.userName;
            state.rememberMe = action.payload.rememberMe;
        },
        clearUserLogin: state => {
            state.userName = "";
            state.rememberMe = false;
        }
    }
});

export const { setUserLogin, clearUserLogin } = userSlice.actions;
export default userSlice.reducer;
