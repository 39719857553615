import { CurrentElectiveInterface, StudentElectiveDataInterface } from "@/ts/interfaces/Electives/ElectiveInterface";
import { DraftElectiveItemInterface } from "@/ts/interfaces/Electives/api/ElectivesApiInterface";
import { router } from "@inertiajs/react";

export const convertToDraftElective = (
    electiveSubject: CurrentElectiveInterface
): DraftElectiveItemInterface => ({
    id: electiveSubject.elective_subject_id,
    name: electiveSubject.elective_subject_name,
    thumbnail: electiveSubject.elective_subject_image_url,
    elective_id: electiveSubject.elective_id,
    learning_area_id:
        electiveSubject.learning_area_id ??
        electiveSubject.elective_learning_area_id,
    learning_area:
        electiveSubject.learning_area ??
        electiveSubject.elective_learning_area_name,
    status: "draft"
});

export const proceedToSelectElectives = (userId: number, grade?: number) => {
    const params: { student: number; pfGrade?: number } = { student: userId, pfGrade: grade };
    if (grade !== undefined) {
        params.pfGrade = grade;
    }

    router.get(route("parents.electives-intro", params));
};

export const proceedToManageElectives = (
    electiveData: StudentElectiveDataInterface,
    studentId: number,
    grade: number
) => {
    if (electiveData && electiveData.electives.current_electives.length > 0) {
        router.get(
            route("parents.student-electives", {
                student: studentId,
                pfGrade: grade
            })
        );
    } else {
        router.get(
            route("parents.electives-intro", {
                student: studentId,
                pfGrade: grade
            })
        );
    }
};

export const transformDraftElectiveResponse = (
   currentElectives: CurrentElectiveInterface[]
): DraftElectiveItemInterface[] => {

    if (currentElectives && currentElectives?.length > 0) {
        const draftElectives = currentElectives?.map(
            (elective: CurrentElectiveInterface) => ({
                id: elective.elective_id,
                name: elective.elective_subject_name,
                thumbnail: elective.elective_subject_image_url,
                elective_id: elective.elective_subject_id,
                learning_area_id: elective.elective_learning_area_id,
                learning_area: elective.elective_learning_area_name,
                status: elective.elective_status
            })
        );
        return draftElectives;
    }
    return [];
};