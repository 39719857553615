import { REQUEST_STATUS } from "@/ts/enums/RequestStatus";
import { StudentEnrolInterface } from "@/ts/interfaces/Enrol/StudentEntrolInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { createStudentEnrolmentThunk } from "../enrolmentThunks";

interface StudentEnrolState {
    enrolStudent: StudentEnrolInterface;

    createStudentEnrolRequestStatus: REQUEST_STATUS;
    createStudentEnrolRequestError: string | null;
}

const initialState: StudentEnrolState = {
    enrolStudent: {
        firstName: "",
        lastName: "",
        startingTerm: "",
        program: null,
        subjectPerformances: [
            {
                id: 1,
                subjectName: "Mathematics",
                level: 1,
                isEnabled: true,
                subFields: null
            },
            {
                id: 2,
                subjectName: "English",
                level: 1,
                isEnabled: true,
                subFields: null
            },
            {
                id: 3,
                subjectName: "The arts",
                level: 1,
                isEnabled: true,
                subFields: [
                    {
                        id: 1,
                        subFieldName: "Drama"
                    },
                    {
                        id: 2,
                        subFieldName: "Music"
                    },
                    {
                        id: 3,
                        subFieldName: "Visual arts"
                    }
                ]
            },
            {
                id: 4,
                subjectName: "Humanities",
                level: 1,
                isEnabled: true,
                subFields: [
                    {
                        id: 1,
                        subFieldName: "Geography"
                    },
                    {
                        id: 2,
                        subFieldName: "History"
                    },
                    {
                        id: 3,
                        subFieldName: "Civics"
                    },
                    {
                        id: 4,
                        subFieldName: "Economics"
                    }
                ]
            },
            {
                id: 5,
                subjectName: "PDHPE",
                level: 1,
                isEnabled: true,
                subFields: [
                    {
                        id: 1,
                        subFieldName: "Health"
                    },
                    {
                        id: 2,
                        subFieldName: "Physical education"
                    }
                ]
            }
        ],
        electives: []
    },

    createStudentEnrolRequestStatus: REQUEST_STATUS.IDLE,
    createStudentEnrolRequestError: null
};

const enrolStudentSlice = createSlice({
    name: "enrolStudent",
    initialState,
    reducers: {
        setEnrolStudent(state, action: PayloadAction<StudentEnrolInterface>) {
            state.enrolStudent = action.payload;
        },
        resetEnrolStudentState() {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            // Create student enrolment
            .addCase(createStudentEnrolmentThunk.pending, state => {
                state.createStudentEnrolRequestStatus = REQUEST_STATUS.PENDING;
                state.createStudentEnrolRequestError = null;
            })
            .addCase(createStudentEnrolmentThunk.fulfilled, state => {
                state.createStudentEnrolRequestStatus = REQUEST_STATUS.SUCCESS;
            })
            .addCase(createStudentEnrolmentThunk.rejected, (state, action) => {
                state.createStudentEnrolRequestStatus = REQUEST_STATUS.ERROR;
                state.createStudentEnrolRequestError = action.payload as string;
            });
    }
});

export const { setEnrolStudent, resetEnrolStudentState } =
    enrolStudentSlice.actions;

export default enrolStudentSlice.reducer;
