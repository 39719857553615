import { ElectiveRulesResponseApiInterface } from "@/ts/interfaces/Electives/api/ElectivesApiInterface";
import { createSlice } from "@reduxjs/toolkit";

import { fetchElectivesRulesThunk } from "../electivesThunk";

interface ElectiveRulesState {
    electiveRules: ElectiveRulesResponseApiInterface | null;
}

const initialState: ElectiveRulesState = {
    electiveRules: {
        min_subject_count: 2,
        max_subject_count: 0,
        learning_areas: [],
        default_elective_slots: 0
    }
};

const electiveRulesSlice = createSlice({
    name: "electiveRules",
    initialState,
    reducers: {
        setElectiveRules: (state, action) => {
            state.electiveRules = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchElectivesRulesThunk.fulfilled, (state, action) => {
            state.electiveRules = action.payload;
        });
        builder.addCase(fetchElectivesRulesThunk.rejected, state => {
            state.electiveRules = null;
        });
        builder.addCase(fetchElectivesRulesThunk.pending, state => {
            state.electiveRules = null;
        });
    }
});

export const { setElectiveRules } = electiveRulesSlice.actions;

export default electiveRulesSlice.reducer;
