import {
    enrolParentApi,
    enrolStudentApi,
    updateSubscriptionGrade
} from "@/api/enrolApi";
import { TokenInfoInterface } from "@/ts/interfaces/Enrol/TokenInfoInterface";
import { CreateParentEnrolmentApiInterface } from "@/ts/interfaces/Enrol/api/CreateParentEnrolmentApiInterface";
import { CreateStudentEnrolmentApiInterface } from "@/ts/interfaces/Enrol/api/CreateStudentEnrolmentApiInterface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

export const createParentEnrolmentThunk = createAsyncThunk(
    "createParentEnrolment",
    async (data: CreateParentEnrolmentApiInterface, { rejectWithValue }) => {
        try {
            const response = await enrolParentApi(data);
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while enroling the parent."
            );
        }
    }
);

export const createStudentEnrolmentThunk = createAsyncThunk(
    "createStudentEnrolment",
    async (data: CreateStudentEnrolmentApiInterface, { rejectWithValue }) => {
        try {
            const response = await enrolStudentApi(data);
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while enroling the student."
            );
        }
    }
);

export const updateTokenInfoThunk = createAsyncThunk(
    "updateTokenInfo",
    async (data: TokenInfoInterface, { rejectWithValue }) => {
        if (!data.programs) {
            return;
        }
        const tokenInfo = {
            gradeId: data.programs?.gradeID,
            id: data.programs?.id
        };

        try {
            const response = await updateSubscriptionGrade(tokenInfo);
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while updating the token info."
            );
        }
    }
);
