import { REQUEST_STATUS } from "@/ts/enums/RequestStatus";
import { ParentEnrolInterface } from "@/ts/interfaces/Enrol/ParentEntrolInterface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { createParentEnrolmentThunk } from "../enrolmentThunks";

interface ParentEnrolState {
    enrolParent: ParentEnrolInterface;

    createParentEnrolRequestStatus: REQUEST_STATUS;
    createParentEnrolRequestError: string | null;
}

const initialState: ParentEnrolState = {
    enrolParent: {
        firstName: "",
        lastName: "",
        reasonId: null,
        referralSourceId: null,
        password: ""
    },

    createParentEnrolRequestStatus: REQUEST_STATUS.IDLE,
    createParentEnrolRequestError: null
};

const enrolParentSlice = createSlice({
    name: "enrolParent",
    initialState,
    reducers: {
        setEnrolParentData(state, action: PayloadAction<ParentEnrolInterface>) {
            state.enrolParent = action.payload;
        },
        resetEnrolParentState() {
            return initialState;
        }
    },
    extraReducers: builder => {
        builder
            // Create parent enrolment
            .addCase(createParentEnrolmentThunk.pending, state => {
                state.createParentEnrolRequestStatus = REQUEST_STATUS.PENDING;
                state.createParentEnrolRequestError = null;
            })
            .addCase(createParentEnrolmentThunk.fulfilled, (state, action) => {
                state.createParentEnrolRequestStatus = REQUEST_STATUS.SUCCESS;
                state.enrolParent = action.payload;
            })
            .addCase(createParentEnrolmentThunk.rejected, (state, action) => {
                state.createParentEnrolRequestStatus = REQUEST_STATUS.ERROR;
                state.createParentEnrolRequestError = action.payload as string;
            });
    }
});

export const { setEnrolParentData, resetEnrolParentState } =
    enrolParentSlice.actions;

export default enrolParentSlice.reducer;
