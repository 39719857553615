import learningAreasReducer from "@/store/features/electives/slices/learningAreasSlice";
import userElectivesReducer from "@/store/features/electives/slices/userElectivesSlice";
import electiveRulesReducer from "@/store/features/electives/slices/electiveRulesSlice";
import useElectivesDispatch from "@/store/features/electives/useElectivesDispatch";
import useElectivesSelector from "@/store/features/electives/useElectivesSelector";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";

const learningAreaPersistConfig = {
    key: "learningAreas",
    storage: sessionStorage
};

const userElectivesPersistConfig = {
    key: "userElectives",
    storage: sessionStorage
};

const electiveRulesPersistConfig = {
    key: "electiveRules",
    storage: sessionStorage
};

const persistedLearningAreaReducer = persistReducer(
    learningAreaPersistConfig,
    learningAreasReducer
);

const persistedUserElectivesReducer = persistReducer(
    userElectivesPersistConfig,
    userElectivesReducer
);

const persistedElectiveRulesReducer = persistReducer(
    electiveRulesPersistConfig,
    electiveRulesReducer
);

const electivesReducer = combineReducers({
    learningAreas: persistedLearningAreaReducer,
    userElectives: persistedUserElectivesReducer,
    electiveRules: persistedElectiveRulesReducer
});

export default electivesReducer;

export { useElectivesDispatch, useElectivesSelector };
