import { deleteStudentElectiveApi, enrolStudentForElectivesApi, fetchElectiveDetailsApi, fetchElectivesListApi, fetchLearningAreasApi, getElectivesRulesApi, getEnrolledStudentElectivesApi, getNotEnrolledStudentElectivesApi, getStudentDraftElectivesApi, saveDraftStudentElectivesApi, validateStudentElectivesApi, validateStudentStateRequirementApi } from "@/api/electivesApi";
import { DeleteStudentElectiveApiRequest, ElectiveListApiRequest, ElectivesRulesParams, EnrolStudentForElectivesApiRequest, EnrolledStudentElectivesApiRequest, GetNotEnrolledStudentElectivesApiRequest, GetStudentDraftElectivesApiRequest, SaveDraftStudentElectivesApiRequest, ValidateStudentElectivesApiRequest, ValidateStudentStateRequirementApiRequest } from "@/ts/interfaces/Electives/api/ElectivesApiInterface";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

// import { setIsSyncServerDraftElectives, setUserSelectedElectives } from "./slices/userElectivesSlice";

export const fetchLearningAreasThunk = createAsyncThunk(
    "fetchLearningAreas",
    async (_, { rejectWithValue }) => {
        try {
            const response = await fetchLearningAreasApi();
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while retrieving learning areas."
            );
        }
    }
);

export const fetchElectivesListThunk = createAsyncThunk(
    "fetchElectivesList",
    async (data: ElectiveListApiRequest, { rejectWithValue }) => {
        try {
            const response = await fetchElectivesListApi(data);
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while retrieving electives."
            );
        }
    }
);

export const fetchEnrolledStudentElectivesThunk = createAsyncThunk(
    "fetchEnrolledStudentElectives",
    async (data: EnrolledStudentElectivesApiRequest, { rejectWithValue }) => {
        try {
            const response = await getEnrolledStudentElectivesApi(
                data.student,
                data.grade
            );
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while retrieving enrolled student electives."
            );
        }
    }
);

export const fetchElectivesRulesThunk = createAsyncThunk(
    "fetchElectivesRules",
    async (data: ElectivesRulesParams, { rejectWithValue }) => {
        try {
            const response = await getElectivesRulesApi(data);
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while retrieving electives rules."
            );
        }
    }
);

export const validateStudentStateRequirementThunk = createAsyncThunk(
    "validateStudentStateRequirement",
    async (
        data: ValidateStudentStateRequirementApiRequest,
        { rejectWithValue }
    ) => {
        try {
            const response = await validateStudentStateRequirementApi(
                data.studentId,
                data.countryId,
                data.stateId,
                data.payload
            );
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while validating student state requirements."
            );
        }
    }
);

export const validateStudentElectivesThunk = createAsyncThunk(
    "validateStudentElectives",
    async (data: ValidateStudentElectivesApiRequest, { rejectWithValue }) => {
        try {
            const response = await validateStudentElectivesApi(
                data.params,
                data.payload
            );
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while validating student's electives."
            );
        }
    }
);

export const saveDraftStudentElectivesThunk = createAsyncThunk(
    "saveDraftStudentElectives",
    async (data: SaveDraftStudentElectivesApiRequest, { rejectWithValue }) => {
        try {
            const response = await saveDraftStudentElectivesApi(
                data.params,
                data.payload
            );
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while saving draft electives."
            );
        }
    }
);

export const enrolStudentForElectivesThunk = createAsyncThunk(
    "enrolStudentForElectives",
    async (
        data: EnrolStudentForElectivesApiRequest,
        { rejectWithValue, dispatch }
    ) => {
        try {
            const response = await enrolStudentForElectivesApi(
                data.params,
                data.payload
            );
            dispatch(
                fetchEnrolledStudentElectivesThunk({
                    student: data.params.student,
                    grade: data.params.pfGrade
                })
            );
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while enroling student for electives."
            );
        }
    }
);

export const fetchStudentDraftElectivesThunk = createAsyncThunk(
    "fetchStudentDraftElectives",
    async (data: GetStudentDraftElectivesApiRequest, { rejectWithValue }) => {
        try {
            const response = await getStudentDraftElectivesApi(data.params);
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while retrieving student draft electives."
            );
        }
    }
);

export const deleteStudentElectiveThunk = createAsyncThunk(
    "deleteStudentElective",
    async (
        data: DeleteStudentElectiveApiRequest,
        { rejectWithValue, dispatch }
    ) => {
        try {
            const response = await deleteStudentElectiveApi(data.params);
            dispatch(
                fetchEnrolledStudentElectivesThunk({
                    student: data.params.student,
                    grade: data.params.grade
                })
            );
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while deleting student elective."
            );
        }
    }
);

export const fetchElectiveDetailsThunk = createAsyncThunk(
    "fetchElectiveDetails",
    async (electiveId: number, { rejectWithValue }) => {
        try {
            const response = await fetchElectiveDetailsApi(electiveId);
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while retrieving elective details."
            );
        }
    }
);

export const fetchNotEnrolledStudentElectivesThunk = createAsyncThunk(
    "fetchNotEnrolledStudentElectives",
    async (
        data: GetNotEnrolledStudentElectivesApiRequest,
        { rejectWithValue }
    ) => {
        try {
            const response = await getNotEnrolledStudentElectivesApi(
                data.token,
                data.gradeId
            );
            return response.data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.message);
            }

            return rejectWithValue(
                "An unexpected error occurred while retrieving not enrolled student electives."
            );
        }
    }
);