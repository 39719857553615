import { REQUEST_STATUS } from "@/ts/enums/RequestStatus";
import {
    CoreSubjectInterface,
    ElectiveDataInterface,
    ElectiveDetailInterface,
    ElectiveTypesInterface,
    NotEnrolStudentElectiveInterface,
    StudentLocationDataInterface,
    WarningInterface
} from "@/ts/interfaces/Electives/ElectiveInterface";
import {
    DraftElectiveItemInterface,
    ElectiveRulesResponseApiInterface
} from "@/ts/interfaces/Electives/api/ElectivesApiInterface";
import { transformDraftElectiveResponse } from "@/utils/electiveHelper";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
    deleteStudentElectiveThunk,
    enrolStudentForElectivesThunk,
    fetchElectiveDetailsThunk,
    fetchElectivesListThunk,
    fetchEnrolledStudentElectivesThunk,
    fetchNotEnrolledStudentElectivesThunk,
    saveDraftStudentElectivesThunk,
    validateStudentStateRequirementThunk
} from "../electivesThunk";

interface UserElectivesState {
    userSelectedElectives: DraftElectiveItemInterface[];
    enrolledStudentElectives: ElectiveTypesInterface | null;
    electiveStudentData: StudentLocationDataInterface | null;
    isAllowElective: boolean;
    isElectivesEnabled: boolean;
    coreSubjects: CoreSubjectInterface[];
    electiveRules: ElectiveRulesResponseApiInterface | null;
    electiveWarnings: WarningInterface[] | null;
    notEnrolledStudentElectives: NotEnrolStudentElectiveInterface | null;
    isSyncServerDraftElectives: boolean;
    isWarningSyncRequired: boolean;
    confirmElectivesResponseMessage: string | null;
    electiveDetails: ElectiveDetailInterface | null;
    electiveSubjectList: ElectiveDataInterface | null;

    getElectiveListRequestStatus: REQUEST_STATUS;
    getElectiveListRequestError: string | null;
    getEnrolledStudentElectivesRequestStatus: REQUEST_STATUS;
    getEnrolledStudentElectivesRequestError: string | null;
    getNotEnrolledStudentElectivesRequestStatus: REQUEST_STATUS;
    getNotEnrolledStudentElectivesRequestError: string | null;

    getValidateStudentStateRequirementRequestStatus: REQUEST_STATUS;
    getValidateStudentStateRequirementRequestError: string | null;

    draftElectiveResponseMessage: string | null;
    saveDraftStudentElectivesRequestStatus: REQUEST_STATUS;
    saveDraftStudentElectivesRequestError: string | null;

    enrolStudentForElectivesRequestStatus: REQUEST_STATUS;
    enrolStudentForElectivesRequestError: string | null;

    deleteStudentElectiveRequestStatus: REQUEST_STATUS;
    deleteStudentElectiveRequestError: string | null;
    deleteStudentElectiveResponseMessage: string | null;

    getElectiveDetailsRequestStatus: REQUEST_STATUS;
    getElectiveDetailsRequestError: string | null;
}

const initialState: UserElectivesState = {
    userSelectedElectives: [],
    enrolledStudentElectives: null,
    electiveRules: null,
    electiveWarnings: null,
    isAllowElective: false,
    isElectivesEnabled: false,
    isWarningSyncRequired: false,
    coreSubjects: [],
    electiveStudentData: null,
    notEnrolledStudentElectives: null,
    electiveDetails: null,
    electiveSubjectList: null,

    confirmElectivesResponseMessage: null,
    isSyncServerDraftElectives: false,
    getElectiveListRequestStatus: REQUEST_STATUS.IDLE,
    getElectiveListRequestError: null,
    getEnrolledStudentElectivesRequestStatus: REQUEST_STATUS.IDLE,
    getEnrolledStudentElectivesRequestError: null,
    getNotEnrolledStudentElectivesRequestStatus: REQUEST_STATUS.IDLE,
    getNotEnrolledStudentElectivesRequestError: null,

    getValidateStudentStateRequirementRequestStatus: REQUEST_STATUS.IDLE,
    getValidateStudentStateRequirementRequestError: null,

    draftElectiveResponseMessage: null,
    saveDraftStudentElectivesRequestStatus: REQUEST_STATUS.IDLE,
    saveDraftStudentElectivesRequestError: null,

    enrolStudentForElectivesRequestStatus: REQUEST_STATUS.IDLE,
    enrolStudentForElectivesRequestError: null,

    deleteStudentElectiveRequestStatus: REQUEST_STATUS.IDLE,
    deleteStudentElectiveRequestError: null,
    deleteStudentElectiveResponseMessage: null,

    getElectiveDetailsRequestStatus: REQUEST_STATUS.IDLE,
    getElectiveDetailsRequestError: null
};

const userElectivesSlice = createSlice({
    name: "userElectives",
    initialState,
    reducers: {
        resetUserElectivesState() {
            return initialState;
        },
        setUserSelectedElectives(
            state,
            action: PayloadAction<DraftElectiveItemInterface[]>
        ) {
            state.userSelectedElectives = action.payload;
        },
        setEnrolledStudentElectives(
            state,
            action: PayloadAction<ElectiveTypesInterface | null>
        ) {
            state.enrolledStudentElectives = action.payload;
        },
        setElectiveRules(
            state,
            action: PayloadAction<ElectiveRulesResponseApiInterface | null>
        ) {
            state.electiveRules = action.payload;
        },
        setNotEnrolledStudentElectives(
            state,
            action: PayloadAction<NotEnrolStudentElectiveInterface | null>
        ) {
            state.notEnrolledStudentElectives = action.payload;
        },
        setIsSyncServerDraftElectives(state, action: PayloadAction<boolean>) {
            state.isSyncServerDraftElectives = action.payload;
        },
        setElectiveWarnings(
            state,
            action: PayloadAction<WarningInterface[] | null>
        ) {
            state.electiveWarnings = action.payload;
            state.isWarningSyncRequired = false;
        },
        addSelectedElectives: (
            state,
            action: PayloadAction<
                DraftElectiveItemInterface | DraftElectiveItemInterface[]
            >
        ) => {
            const electivesArray = Array.isArray(action.payload)
                ? action.payload
                : [action.payload];

            const newElectives = electivesArray.filter(
                elective =>
                    !state.userSelectedElectives.some(e => e.id === elective.id)
            );

            state.userSelectedElectives = [
                ...state.userSelectedElectives,
                ...newElectives
            ];
        },
        addSelectedElectivesFromNotEnrolledStudentElectives: (
            state,
            action: PayloadAction<DraftElectiveItemInterface[]>
        ) => {
            state.userSelectedElectives = [
                ...state.userSelectedElectives,
                ...action.payload
            ];
        },
        removeSelectedElectives: (
            state,
            action: PayloadAction<DraftElectiveItemInterface>
        ) => {
            state.userSelectedElectives = state.userSelectedElectives.filter(
                selectedElective => selectedElective.id !== action.payload.id
            );
        },
        setIsWarningSyncRequired(state, action: PayloadAction<boolean>) {
            state.isWarningSyncRequired = action.payload;
        },
        setConfirmElectivesResponseMessage(
            state,
            action: PayloadAction<string | null>
        ) {
            state.confirmElectivesResponseMessage = action.payload;
        },
        setElectiveDetails(
            state,
            action: PayloadAction<ElectiveDetailInterface | null>
        ) {
            state.electiveDetails = action.payload;
        }
    },
    extraReducers: builder => {
        builder
            // Fetch electives list
            .addCase(fetchElectivesListThunk.pending, state => {
                state.getElectiveListRequestStatus = REQUEST_STATUS.PENDING;
                state.getElectiveListRequestError = null;
            })
            .addCase(fetchElectivesListThunk.fulfilled, (state, action) => {
                state.getElectiveListRequestStatus = REQUEST_STATUS.SUCCESS;
                state.electiveSubjectList = action.payload?.data;
            })
            .addCase(fetchElectivesListThunk.rejected, (state, action) => {
                state.getElectiveListRequestStatus = REQUEST_STATUS.ERROR;
                state.getElectiveListRequestError = action.payload as string;
            })

            // Fetch enrolled student electives
            .addCase(fetchEnrolledStudentElectivesThunk.pending, state => {
                state.getEnrolledStudentElectivesRequestStatus =
                    REQUEST_STATUS.PENDING;
                state.getEnrolledStudentElectivesRequestError = null;
            })
            .addCase(
                fetchEnrolledStudentElectivesThunk.fulfilled,
                (state, action) => {
                    console.log(
                        "fetchEnrolledStudentElectivesThunk.fulfilled",
                        action.payload
                    );
                    state.getEnrolledStudentElectivesRequestStatus =
                        REQUEST_STATUS.SUCCESS;
                    const electiveResponse = action.payload?.data?.electives;
                    const savedElectives = transformDraftElectiveResponse(
                        action.payload?.data?.electives?.current_electives
                    );
                    state.userSelectedElectives = savedElectives;
                    state.enrolledStudentElectives = electiveResponse;
                    state.isAllowElective =
                        action.payload?.data.is_allow_elective;
                    state.isElectivesEnabled =
                        action.payload?.data.is_electives_enabled;
                    state.coreSubjects = action.payload?.data.core_subjects;
                    state.electiveStudentData = action.payload?.data.student;

                    state.electiveRules = action.payload.data.elective_rule;
                    state.isWarningSyncRequired = true;
                    state.isSyncServerDraftElectives = false;
                }
            )
            .addCase(
                fetchEnrolledStudentElectivesThunk.rejected,
                (state, action) => {
                    state.getEnrolledStudentElectivesRequestStatus =
                        REQUEST_STATUS.ERROR;
                    state.getEnrolledStudentElectivesRequestError =
                        action.payload as string;
                }
            )

            // Fetch not-enrolled student electives
            .addCase(fetchNotEnrolledStudentElectivesThunk.pending, state => {
                state.getNotEnrolledStudentElectivesRequestStatus =
                    REQUEST_STATUS.PENDING;
                state.getNotEnrolledStudentElectivesRequestError = null;
            })
            .addCase(
                fetchNotEnrolledStudentElectivesThunk.fulfilled,
                (state, action) => {
                    state.getNotEnrolledStudentElectivesRequestStatus =
                        REQUEST_STATUS.SUCCESS;
                    state.notEnrolledStudentElectives = action.payload;
                }
            )
            .addCase(
                fetchNotEnrolledStudentElectivesThunk.rejected,
                (state, action) => {
                    state.getNotEnrolledStudentElectivesRequestStatus =
                        REQUEST_STATUS.ERROR;
                    state.getNotEnrolledStudentElectivesRequestError =
                        action.payload as string;
                }
            )

            // Validate student state requirement
            .addCase(validateStudentStateRequirementThunk.pending, state => {
                state.getValidateStudentStateRequirementRequestStatus =
                    REQUEST_STATUS.PENDING;
                state.getValidateStudentStateRequirementRequestError = null;
            })
            .addCase(validateStudentStateRequirementThunk.fulfilled, state => {
                state.getValidateStudentStateRequirementRequestStatus =
                    REQUEST_STATUS.SUCCESS;
                state.isWarningSyncRequired = false;
            })
            .addCase(
                validateStudentStateRequirementThunk.rejected,
                (state, action) => {
                    state.getValidateStudentStateRequirementRequestStatus =
                        REQUEST_STATUS.ERROR;
                    state.getValidateStudentStateRequirementRequestError =
                        action.payload as string;
                    state.isWarningSyncRequired = false;
                }
            )

            // Save draft student electives
            .addCase(saveDraftStudentElectivesThunk.pending, state => {
                state.saveDraftStudentElectivesRequestStatus =
                    REQUEST_STATUS.PENDING;
                state.saveDraftStudentElectivesRequestError = null;
            })
            .addCase(saveDraftStudentElectivesThunk.fulfilled, (state, action) => {
                state.saveDraftStudentElectivesRequestStatus =
                    REQUEST_STATUS.SUCCESS;
                state.draftElectiveResponseMessage = action.payload.message;
            })
            .addCase(
                saveDraftStudentElectivesThunk.rejected,
                (state, action) => {
                    state.saveDraftStudentElectivesRequestStatus =
                        REQUEST_STATUS.ERROR;
                    state.saveDraftStudentElectivesRequestError =
                        action.payload as string;
                }
            )

            // Enrol student for electives
            .addCase(enrolStudentForElectivesThunk.pending, state => {
                state.enrolStudentForElectivesRequestStatus =
                    REQUEST_STATUS.PENDING;
                state.enrolStudentForElectivesRequestError = null;
            })
            .addCase(
                enrolStudentForElectivesThunk.fulfilled,
                (state, action) => {
                    state.enrolStudentForElectivesRequestStatus =
                        REQUEST_STATUS.SUCCESS;
                    state.confirmElectivesResponseMessage =
                        action.payload.message;
                }
            )
            .addCase(
                enrolStudentForElectivesThunk.rejected,
                (state, action) => {
                    state.enrolStudentForElectivesRequestStatus =
                        REQUEST_STATUS.ERROR;
                    state.enrolStudentForElectivesRequestError =
                        action.payload as string;
                }
            )

            // Delete student elective
            .addCase(deleteStudentElectiveThunk.pending, state => {
                state.deleteStudentElectiveRequestStatus =
                    REQUEST_STATUS.PENDING;
                state.deleteStudentElectiveRequestError = null;
            })
            .addCase(deleteStudentElectiveThunk.fulfilled, (state, action) => {
                state.deleteStudentElectiveRequestStatus =
                    REQUEST_STATUS.SUCCESS;
                state.deleteStudentElectiveResponseMessage =
                    action.payload.message;
            })
            .addCase(deleteStudentElectiveThunk.rejected, (state, action) => {
                state.deleteStudentElectiveRequestStatus = REQUEST_STATUS.ERROR;
                state.deleteStudentElectiveRequestError =
                    action.payload as string;
            })

            // Fetch elective details
            .addCase(fetchElectiveDetailsThunk.pending, state => {
                state.getElectiveDetailsRequestStatus = REQUEST_STATUS.PENDING;
                state.getElectiveDetailsRequestError = null;
            })
            .addCase(fetchElectiveDetailsThunk.fulfilled, (state, action) => {
                state.getElectiveDetailsRequestStatus = REQUEST_STATUS.SUCCESS;
                state.electiveDetails = action.payload.data;
            })
            .addCase(fetchElectiveDetailsThunk.rejected, (state, action) => {
                state.getElectiveDetailsRequestStatus = REQUEST_STATUS.ERROR;
                state.getElectiveDetailsRequestError = action.payload as string;
            });
    }
});

export const {
    resetUserElectivesState,
    setUserSelectedElectives,
    setEnrolledStudentElectives,
    setElectiveRules,
    setElectiveWarnings,
    setNotEnrolledStudentElectives,
    setIsSyncServerDraftElectives,
    addSelectedElectives,
    removeSelectedElectives,
    setIsWarningSyncRequired,
    setConfirmElectivesResponseMessage,
    setElectiveDetails
} = userElectivesSlice.actions;

export default userElectivesSlice.reducer;