/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";

import { CreateParentEnrolmentApiInterface } from "@ts/interfaces/Enrol/api/CreateParentEnrolmentApiInterface";
import { CreateStudentEnrolmentApiInterface } from "@ts/interfaces/Enrol/api/CreateStudentEnrolmentApiInterface.ts";

declare function route(
    name: string,
    params?: string | number | { [key: string]: string }
): string;

export const enrolParentApi = (data: CreateParentEnrolmentApiInterface) => {
    return axios.post(route("api.enrolment.parent.onboard"), data);
};

export const enrolStudentApi = (data: CreateStudentEnrolmentApiInterface) => {
    return axios.post(route("api.enrolment.student.onboard"), data);
};

export const updateSubscriptionGrade = (data: { gradeId: number | undefined; id: number | undefined }) => {
    return axios.post(route("api.subscription.updateSubscriptionGrade"), data);
};

export const getLicenseProductFamily = (productFamilyName: string, gradeId: number, programId: number | undefined) => {
    return axios.post(route("api.euka.getProductFamilyData"), { productFamilyName, gradeId, programId });
};
