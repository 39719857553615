import { REQUEST_STATUS } from "@/ts/enums/RequestStatus";
import { createSlice } from "@reduxjs/toolkit";

import { CancelProgramParams, cancelProgram } from "../programThunks";

interface ProgramState {
    cancelProgramStatus: REQUEST_STATUS;
    error: string | null;
    isSuccess: boolean;
    message: string | null;
    status: string | null;
    data: CancelProgramParams | null;
}

const initialState: ProgramState = {
    cancelProgramStatus: REQUEST_STATUS.IDLE,
    error: null,
    isSuccess: false,
    message: null,
    status: null,
    data: null
};

const programSlice = createSlice({
    name: "parentProgram",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(cancelProgram.pending, state => {
            state.cancelProgramStatus = REQUEST_STATUS.PENDING;
            state.data = null;
        });
        builder.addCase(cancelProgram.fulfilled, (state, action) => {
            state.cancelProgramStatus = REQUEST_STATUS.SUCCESS;
            state.data = action.payload;
        });
        builder.addCase(cancelProgram.rejected, state => {
            state.cancelProgramStatus = REQUEST_STATUS.ERROR;
        });
    }
});

export default programSlice.reducer;