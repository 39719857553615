import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider as ReduxProvider } from "react-redux";

import { store } from "@/store";
import theme from "@/theme";
import EchoProvider from "@providers/EchoProvider";
import "@styles/main.scss";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { ChakraBaseProvider } from "@chakra-ui/react";
import { logAppError } from "@utils/logAppError";
import { ErrorBoundaryFBContainer } from "@containers/Misc/ErrorBoundaryFBContainer";

interface PropTypes {
    children: React.ReactNode;
}

const queryClient = new QueryClient();

const persistor = persistStore(store);

const AppProvider = ({ children }: PropTypes) => {
    return (
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ChakraBaseProvider theme={theme}>
                    <QueryClientProvider client={queryClient}>
                        <ErrorBoundary
                            FallbackComponent={ErrorBoundaryFBContainer}
                            onError={logAppError}
                        >
                            <EchoProvider>{children}</EchoProvider>
                        </ErrorBoundary>
                    </QueryClientProvider>
                </ChakraBaseProvider>
            </PersistGate>
        </ReduxProvider>
    );
};

export default AppProvider;