import {
    cancelInstalmentProgramEarlyApi,
    cancelProgramApi
} from "@/api/parentsApi";
import { createAsyncThunk } from "@reduxjs/toolkit";

export interface CancelProgramParams {
    type: "addon" | "plan";
    licenseId?: number;
    itemId?: number;
    studentId: number;
    reasonId: number | null;
    cancellationType: "refund" | "non-refund";
    instalmentEarlyCancel: boolean;
}

export const cancelProgram = createAsyncThunk(
    "cancelProgram",
    async (data: CancelProgramParams, { rejectWithValue }) => {
        try {
            if (data.instalmentEarlyCancel) {
                const response = await cancelInstalmentProgramEarlyApi({
                    licenseId: data.licenseId!,
                    reasonId: data.reasonId!
                });
                return response.data;
            }

            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { instalmentEarlyCancel, ...body } = data;
            const response = await cancelProgramApi(body);

            return response.data;
        } catch (error) {
            rejectWithValue(
                error instanceof Error ? error.message : "An error occurred"
            );
            throw error;
        }
    }
);
